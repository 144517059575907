<template>
  <div class="page_bg" style="padding:0.4rem">
    <div class=" flex-r-s-c" @click="$utils.pathGoBack(-1)">
      <van-icon name="arrow-left" style="font-size: 0.57rem;margin-bottom:0.5rem" />
    </div>
    <div class="help-box">
      <div class="help-body" style="line-height: 1.8;padding-bottom: 30upx;">
        <div style="font-weight: bold;">
          请您认真阅读本协议尤其是免除或者限制兽医首选（下称本平台）责任的条款及其它限制您权利的条款，一旦您注册即表示您已经阅读并且同意与本平台达成协议，完全理解并接受所有的注册条款。阅读本协议过程中，如果您不同意本协议或其中任何条款约定，您应立即停止注册程序。
        </div>
        <div style="font-weight: bold;" class="margin-top">一、总则</div>
        <div>1.1 兽医首选的所有权和运营权归南京瀚星信息技术有限公司所有。</div>
        <div>1.2
          用户在注册之前，应当仔细阅读本协议，并同意遵守本协议后方可成为注册用户。一旦注册成功，则用户与兽医首选之间自动形成协议关系，用户应当受本协议的约束。用户在使用特殊的服务或产品时，应当同意接受相关协议后方能使用。
        </div>
        <div>1.3 本协议则可由兽医首选随时更新，用户应当及时关注并同意兽医首选不承担通知义务。兽医首选的通知、公告、声明或其它类似内容是本协议的一部分。</div>
        <div style="font-weight: bold;" class="margin-top">二、服务内容</div>
        <div>2.1 兽医首选的具体内容由兽医首选运营方根据实际情况提供。</div>
        <div>2.2
          兽医首选仅提供相关的网络服务，除此之外与相关网络服务有关的设备(如个人电脑、手机、及其他与接入互联网或移动网有关的装置)及所需的费用(如为接入互联网而支付的电话费及上网费、为使用移动网而支付的手机费)均应由用户自行负担。
        </div>
        <div style="font-weight: bold;" class="margin-top">三、用户帐号</div>
        <div>3.1 经兽医首选注册系统完成注册程序并通过身份认证的用户即成为正式用户，可以获得兽医首选规定用户所应享有的一切权限；未经认证仅享有兽医首选规定的部分会员权限。兽医首选有权对会员的权限设计进行变更。
        </div>
        <div>3.2
          用户只能按照注册要求使用其名下手机号码注册。用户有义务保证密码和帐号的安全，用户利用该密码和帐号所进行的一切活动引起的任何损失或损害，由用户自行承担全部责任，兽医首选不承担任何责任。如用户发现帐号遭到未授权的使用或发生其他任何安全问题，应立即修改帐号密码并妥善保管，如有必要，请通知兽医首选。因黑客行为或用户的保管疏忽导致帐号非法使用，兽医首选不承担任何责任。
        </div>
        <div style="font-weight: bold;" class="margin-top">四、使用规则</div>
        <div>4.1
          遵守中华人民共和国相关法律法规，包括但不限于《中华人民共和国计算机信息系统安全保护条例》、《计算机软件保护条例》、《最高人民法院关于审理涉及计算机网络著作权纠纷案件适用法律若干问题的解释(法释[2004]1号)》、《全国人大常委会关于维护互联网安全的决定》、《互联网电子公告服务管理规定》、《互联网新闻信息服务管理规定》、《互联网著作权行政保护办法》和《信息网络传播权保护条例》等有关计算机互联网规定和知识产权的法律和法规、实施办法。
        </div>
        <div>4.2 用户对其自行发表、上传或传送的内容负全部责任，所有用户不得在兽医首选任何页面发布、转载、传送含有下列内容之一的信息，否则兽医首选有权自行处理并不通知用户：</div>
        <div>(1)违反宪法确定的基本原则的；</div>
        <div>(2)危害国家安全，泄漏国家机密，颠覆国家政权，破坏国家统一的；</div>
        <div>(3)损害国家荣誉和利益的；</div>
        <div>(4)煽动民族仇恨、民族歧视，破坏民族团结的；</div>
        <div>(5)破坏国家宗教政策，宣扬邪教和封建迷信的；</div>
        <div>(6)散布谣言，扰乱社会秩序，破坏社会稳定的；</div>
        <div>(7)散布淫秽、色情、赌博、暴力、恐怖或者教唆犯罪的；</div>
        <div>(8)侮辱或者诽谤他人，侵害他人合法权益的；</div>
        <div>(9)煽动非法集会、结社、游行、示威、聚众扰乱社会秩序的；</div>
        <div>(10)以非法民间组织名义活动的；</div>
        <div>(11)含有法律、行政法规禁止的其他内容的。</div>
        <div>4.3
          用户承诺对其发表或者上传于兽医首选的所有信息(即属于《中华人民共和国著作权法》规定的作品，包括但不限于文字、图片、音乐、电影、表演和录音录像制品和电脑程序等)均享有完整的知识产权，或者已经得到相关权利人的合法授权；如用户违反本条规定造成兽医首选被第三人索赔的，用户应全额补偿兽医首选一切费用(包括但不限于各种赔偿费、诉讼代理费及为此支出的其它合理费用)；
        </div>
        <div>4.4
          当第三方认为用户发表或者上传于兽医首选的信息侵犯其权利，并根据《信息网络传播权保护条例》或者相关法律规定向兽医首选发送权利通知书时，用户同意兽医首选可以自行判断决定删除涉嫌侵权信息，除非用户提交书面证据材料排除侵权的可能性，兽医首选将不会自动恢复上述删除的信息；
        </div>
        <div>(1)不得为任何非法目的而使用网络服务系统；</div>
        <div>(2)遵守所有与网络服务有关的网络协议、规定和程序； </div>
        <div>(3)不得利用兽医首选进行任何可能对互联网的正常运转造成不利影响的行为；</div>
        <div>(4)不得利用兽医首选进行任何不利于兽医首选的行为。</div>
        <div>4.5 如用户在使用网络服务时违反上述任何规定，兽医首选有权要求用户改正或直接采取一切必要的措施(包括但不限于删除用户张贴的内容、暂停或终止用户使用网络服务的权利)以减轻用户不当行为而造成的影响。</div>
        <div style="font-weight: bold;" class="margin-top">五、隐私保护</div>
        <div>5.1 兽医首选非常重视您的个人信息保护，保证在合法、正当与必要的原则下收集、使用或者公开您的个人信息且不会收集与提供和本产品服务无关的个人信息。</div>
        <div>5.2
          用户在注册帐号或使用本服务的过程中，为了更好地提供相关服务，兽医首选可能需要用户填写或提交一些必要的基础信息，如法律法规、规章规范性文件（以下称“法律法规”）规定的需要填写的身份信息。如提交的信息不完整或不符合法律法规的规定，则可能无法使用本服务或在使用本服务的过程中受到限制。
        </div>
        <div>5.3兽医首选收集的用户信息包括：</div>
        <div>1）用户在注册时需自行提供的必要个人/公司信息（包括不限于如注册时填写的手机号码、公司、职位等信息）；</div>
        <div>2）本平台为提供服务过程中合法收集的用户个人/公司信息，如用户使用服务时的设备信息、账号信息、日志信息、公司资料、结算账户信息等。请注意，单独的设备信息、日志信息等是无法识别特定自然人身份的信息；</div>
        <div>
          3）地理位置信息：因兽医首选提供的服务系基于地理位置提供的移动社交服务，用户地理位置信息将作为用户公开资料之一，由兽医首选向其他用户公开以便兽医首选向用户提供基于地理位置的相关服务。在收集用户的地理位置信息前，本平台将单独向用户发出权限开放申请，如用户不愿意提供地理位置信息，可选择拒绝。用户在提供地理位置信息后，也可随时通过用户的设备系统设置终止向其他用户公开用户的地理位置信息。
        </div>
        <div>4）兽医首选可能会与第三方合作向用户提供相关的网络服务，在此情况下，如该第三方同意承担与兽医首选同等的保护用户隐私的责任，则兽医首选有权将用户的注册资料等提供给该第三方。</div>
        <div>5)在不透露单个用户隐私资料的前提下，兽医首选有权对整个用户数据库进行分析并对用户数据库进行商业上的利用。</div>
        <div>5.4本平台承诺，本平台收集您的个人信息将仅用于创建本平台账号、为您提供和展示推送本平台的商品或服务、为您提供安全保障等用途，具体用途如下：</div>
        <div>(1) 本平台向用户及时发送重要通知，如软件更新、本协议条款的变更；</div>
        <div>(2) 本平台内部进行审计、数据分析和研究等，以改进本平台的产品、服务和与用户之间的沟通；</div>
        <div>(3) 依本协议约定，本平台提供相关服务、管理、审查用户信息及进行处理措施；</div>
        <div>(4) 适用法律法规规定的其他事项。</div>
        <div>除上述事项外，如未取得用户事先同意，本平台不会将用户个人信息使用于任何其他用途。如果本平台将您的个人信息用于任何未载明的其他用途，或者将基于特定目的收集而来的信息用于其他目的时，会事先征求您的同意。
        </div>
        <div style="font-weight: bold;" class="margin-top">六、版权声明</div>
        <div>6.1 兽医首选的文字、图片、音频、视频等版权均归南京瀚星信息科技有限公司享有或与作者共同享有，未经兽医首选许可，不得任意转载。</div>
        <div>6.2 兽医首选特有的标识、版面设计、编排方式等版权均属南京瀚星信息科技有限公司享有，未经兽医首选许可，不得任意复制或转载。</div>
        <div>6.3 使用兽医首选的任何内容均应注明“来源于兽医首选”及署上作者姓名，按法律规定需要支付稿酬的，应当通知兽医首选及作者及支付稿酬，并独立承担一切法律责任。</div>
        <div>6.4 兽医首选享有所有作品用于其它用途的优先权，包括但不限于网站、电子杂志、平面出版等，但在使用前会通知作者，并按同行业的标准支付稿酬。</div>
        <div>6.5 兽医首选所有内容仅代表作者自己的立场和观点，与兽医首选无关，由作者本人承担一切法律责任。</div>
        <div>6.6 恶意转载兽医首选内容的，兽医首选保留将其诉诸法律的权利。</div>
        <div style="font-weight: bold;" class="margin-top">七、责任声明</div>
        <div>7.1 用户明确同意其使用兽医首选网络服务所存在的风险及一切后果将完全由用户本人承担，兽医首选对此不承担任何责任。</div>
        <div>7.2 兽医首选无法保证网络服务一定能满足用户的要求，也不保证网络服务的及时性、安全性、准确性。</div>
        <div>7.3 兽医首选不保证为方便用户而设置的外部链接的准确性和完整性，同时，对于该等外部链接指向的不由兽医首选实际控制的任何网页上的内容，兽医首选不承担任何责任。</div>
        <div>7.4 对于因不可抗力或兽医首选不能控制的原因造成的网络服务中断或其它缺陷，兽医首选不承担任何责任，但将尽力减少因此而给用户造成的损失和影响。</div>
        <div>7.5 对于站向用户提供的下列产品或者服务的质量缺陷本身及其引发的任何损失，兽医首选无需承担任何责任：</div>
        <div>(1)兽医首选向用户免费提供的各项网络服务；</div>
        <div>(2)兽医首选向用户赠送的任何产品或者服务。</div>
        <div>7.6 兽医首选有权于任何时间暂时或永久修改或终止本服务(或其任何部分)，而无论其通知与否，兽医首选对用户和任何第三人均无需承担任何责任。</div>
        <div style="font-weight: bold;" class="margin-top">八、附则</div>
        <div>8.1 用户与兽医首选就本协议内容或其执行发生任何争议，应进行友好协商；协商不成时，任何一方均可向兽医首选运营方人民法院提起诉讼。</div>
        <div>8.2 本协议之订立、生效、解释、修订、补充、终止、执行与争议解决均适用于中华人民共和国大陆地区法律，如本协议中的任何条款无论因何种原因完全或部分无效或不具有执行力，本协议的其余条款仍应有效并且有约束力。
        </div>
        <div>8.3 本协议解释权及修订权归南京瀚星信息科技有限公司所有。</div>
      </div>
    </div>
  </div>
</template>

<script>
import './index.scss'
export default {
  data() {
    return {

    }
  },
  created() {

  },
  methods: {

  }
}
</script>
